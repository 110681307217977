<template>
    <van-pull-refresh v-model="isPullRefresh" success-text="刷新成功" @refresh="onRefresh">
        <div class="index">
            <!-- 自定义头部 -->
            <div class="comp-navbar">
                <div class="placeholder-bar">
                    <img class="header-bg" src="@/assets/yq/index-header-bg-02.png" />
                </div>
                <div class="navbar">
                    <div class="nav-statusbar"></div>
                    <div class="nav-titlebar" :style="{ height: titleBarHeight + 'px' }">
                        <!-- <div class="bar-options">
                        <div
                            v-if="backVisible"
                            class="opt opt-back"
                            @click="backClick()"
                        >
                            <img
                                class="back-image"
                                src="@/assets/yq/back.png"
                            />
                        </div>
                        <div class="line" v-if="backVisible && homePath"></div>
                    </div> -->
                        <div class="bar-title">卓猎云签</div>
                    </div>
                </div>
            </div>
            <!-- 搜索 -->
            <div class="search">
                <img src="@/assets/yq/search-icon.png" />
                <form @submit.prevent action="#">
                    <input type="text" placeholder="请输入关键字搜索" @keyup.13="serachRequest" v-model="condition.name" />
                </form>
                <span class="close" @click="handleClearSearch" v-if="condition.name"><van-icon name="close" size="20"  /></span>
            </div>
            <!-- 列表不为空 -->
            <div class="list-header bg-fff">
                <div class="tab" v-for="(item, index) in tabsData" :key="index" @click="handleTab(item.status)">
                    <div style="text-align: center;">
                        <img :src="item.img" v-show="tabIndex != item.status" />
                        <img :src="item.imgActive" v-show="tabIndex == item.status" />
                        <span v-show="tabIndex != item.status" class="tab">
                            {{item.name}}
                        </span>
                        <span v-show="tabIndex == item.status" class="tab active">
                            {{item.name}}
                        </span>
                    </div>
                    <div class="red-spot color-fff" v-show="item.new > 0">
                        {{ item.new }}
                    </div>
                    <!-- <div style="text-align: center;">
					
				</div> -->
                </div>
            </div>
            <div class="list-content">
                <div v-if="tabIndex == 1">
                    <div v-if="contractWait&&contractWait.length">
                        <contract-item v-for="(item, index) in contractWait" :key="index" :contractObj="item" :authRes="indexAuthRes" :agreements="AgreementsInfo" @social="handleSocialDialog" />
                        <div class="bottom-line">我是有底线的~</div>
                    </div>
                    <!-- 数据为空 -->
                    <div v-if="isEmpty">
                        <index-empty />
                    </div>
                </div>
                <div v-if="tabIndex == 2">
                    <div v-if="contractCom&&contractCom.length">
                        <contract-item v-for="(item, index) in contractCom" :key="index" :contractObj="item" :authRes="indexAuthRes" />
                        <div class="bottom-line">我是有底线的~</div>
                    </div>
                    <!-- 数据为空 -->
                    <div v-if="isEmpty">
                        <index-empty />
                    </div>
                </div>
                <div v-if="tabIndex == 3">
                    <div v-if="contractOverdue&&contractOverdue.length">
                        <contract-item v-for="(item, index) in contractOverdue" :key="index" :contractObj="item" :authRes="indexAuthRes" />
                        <div class="bottom-line">我是有底线的~</div>
                    </div>
                    <!-- 数据为空 -->
                    <div v-if="isEmpty">
                        <index-empty />
                    </div>
                </div>
                <div v-if="tabIndex == 4">
                    <div v-if="contractRevoke&&contractRevoke.length">
                        <contract-item v-for="(item, index) in contractRevoke" :key="index" :contractObj="item" :authRes="indexAuthRes" />
                        <div class="bottom-line">我是有底线的~</div>
                    </div>

                    <!-- 数据为空 -->
                    <div v-if="isEmpty">
                        <index-empty />
                    </div>
                </div>
                <div v-if="tabIndex == 5">
                    <!-- 数据不为空 -->
                    <div v-if="contractRefuse&&contractRefuse.length">
                        <contract-item v-for="(item, index) in contractRefuse" :key="index" :contractObj="item" :authRes="indexAuthRes" />
                        <div class="bottom-line">我是有底线的~</div>
                    </div>
                    <!-- 数据为空 -->
                    <div v-if="isEmpty">
                        <index-empty />
                    </div>

                </div>
            </div>
            <!-- 客服电话 -->
            <div class="customer-service bg-fff" @click.stop="callTelephone">
                <a :href="'tel:' + callPhone">
                    <img src="@/assets/yq/customer-service.png" />
                </a>
            </div>
            <van-dialog v-model="dialogShow" title="请注意" show-cancel-button message="我方为您推荐的工作单位会为您缴纳社保。请确认您在该合同履行期间的社保缴纳情况，确保其为<span style='color:#2D96FF'>唯—社保</span>。" confirm-button-text="已知晓，前往签署" message-align="left" confirm-button-color="#2D96FF" @confirm="socialConfirm" @cancel="socialCancel" />
        </div>
    </van-pull-refresh>
</template>

<script>
import contractItem from "@/components/index/contractItem.vue";
import indexEmpty from "@/components/index/indexEmpty.vue";
import { getContractList, getContractEveryNum, getContractFile } from "@/api/contract";
import config from "@/config/config";
import { Dialog } from 'vant';


export default {
    components: {
        indexEmpty,
        contractItem
    },
    data() {
        return {
            isPullRefresh: false,
            headerHeight: 125,
            titleBarHeight: 50,
            keyword: "",
            loading: true,
            current: 1, //第一页
            dow: false, //底部提示默认隐藏
            /*
            *功能：table切换内容
            * */
            tabsData: [
                {
                    status: 1,
                    name: "待签署",
                    img: require("@/assets/yq/index-wait-sign.png"),
                    //require("@/assets/mobile/userImg.png")
                    imgActive: require("@/assets/yq/index-wait-sign-active.png"),
                    new: 0,
                    value: 'notSignature'
                },
                {
                    status: 2,
                    name: "已签署",
                    img: require("@/assets/yq/index-com-sign.png"),
                    imgActive: require("@/assets/yq/index-com-sign-active.png"),
                    new: 0,
                    value: 'signature'
                },
                {
                    status: 3,
                    name: "已逾期",
                    img: require("@/assets/yq/index-overdue.png"),
                    imgActive: require("@/assets/yq/index-overdue-active.png"),
                    new: 0,
                    value: 'overdue'
                },
                {
                    status: 4,
                    name: "已作废",
                    img: require("@/assets/yq/index-revoke.png"),
                    imgActive: require("@/assets/yq/index-revoke-active.png"),
                    new: 0,
                    value: 'cancel'
                },
                // {
                //    status: 5,
                //     name: "已拒签",
                //     img: require("@/assets/yq/index-refuse.png"),
                //     imgActive: require("@/assets/yq/index-refuse-active.png"),
                //     new: 0,
                // },
            ],
            isEmpty: false,
            tabIndex: 1,
            tabColor: "#0371FC",
            tabPost: "0",
            bgColors: "#fff",
            name: "卓猎云签",
            // 列表数据
            contractWait: [],
            contractCom: [],
            contractOverdue: [],
            contractRevoke: [],
            contractRefuse: [],
            contractArr: [
                "contractWait",
                "contractCom",
                "contractOverdue",
                "contractRevoke",
                "contractRefuse",
            ],
            callPhone: "",
            indexAuthRes: null,
            dialogShow: false,
            clickId: "",
            AgreementsInfo: {},
            condition: {
                code: "",
                current: 1,
                name: "",
                size: 10000,
                status: 1
            }
        }
    },
    created() {
        console.log('/#/index');
        let { status } = this.$route.query;
        // console.log('index-query',this.$route.query);
        status = status ? status : 1;
        //请求列表
        this.tabIndex = status;
        this.isEmpty = false;
        this.contractWait = [];
        this.contractCom = [];
        this.contractOverdue = [];
        this.contractRevoke = [];
        this.condition.status = status
        this.requestContract(this.condition);
        // 设置客服电话
        this.callPhone = config.telePhone;
    },
    methods: {
        handleSocialDialog({ id }) {
            this.dialogShow = true;
            this.clickId = id;
            console.log(this.clickId);
        },
        socialConfirm() {
            console.log('发送请求');
            let id = this.clickId;
            getContractFile({ id }).then(res => {
                let { status, statusMessage } = res;
                if (status) {
                    // let url = encodeURIComponent(res.data);
                    let url = res.data;
                    window.location.href = url;
                    return;
                } else {
                    this.$toast({
                        message: statusMessage
                    });
                }
            })
        },
        socialCancel() {
            this.dialogShow = false;
        },
        handleTab(status) {
            this.tabIndex = status;
            this.condition.status = this.tabIndex;
            this.requestContract(this.condition);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        onRefresh() {
            let status = this.tabIndex;
            this.requestContract({ status });
            this.isPullRefresh = false;
        },
        requestContract(params) {

            this.isEmpty = false;
            //请求数量
            getContractEveryNum({ name: '' }).then((res) => {
                console.log('getContractEveryNum---',res);
                let { code } = res;
                if (code === 0) {
                    this.handleContractNum(res.data);
                }
                //请求列表
                getContractList(params).then((res) => {
                    console.log(res);
                    let { code, data, message } = res;
                    if(code !== 0){
                        return this.$toast({ message })
                    }
                    let listName = this.contractArr[params.status - 1];
                    console.log(listName);
                    if (data.records && data.records.length > 0) {
                        this[listName] = data.records;
                    } else {
                        this[listName] = [];
                        this.isEmpty = true;
                    }

                });

            });
        },
        handleClearSearch(){
            this.condition.name = ''
            this.serachRequest()
        },
        serachRequest() {
            this.requestContract(this.condition);
        },
        handleContractNum(obj) {
            if(!obj) return;
            this.tabsData.forEach(v=> {
                v.new = obj[v.value] || 0
            })
            console.log('this.tabsData--',this.tabsData);
        },
        callTelephone() {
            // wx.makePhoneCall({
            // 	phoneNumber: config.telePhone,
            // });
        },

    },
}
</script>

<style lang="scss" scoped>
.index {
    overflow: hidden;
}

/* 头部 */
.placeholder-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 30;
    background: #fff;
    height: 130px;
}
.header-bg {
    width: 100%;
    height: 124px;
    // position: absolute;
    // top: -44px;
    // left: 0;
    // z-index:50;
}
.navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 30;
    height: 130px;
}
.nav-titlebar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.bar-options {
    /* width: 170rpx; */
    height: 60rpx;
    display: flex;

    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    left: 7px;
    display: flex;
    align-items: center;
    /* background: hsla(0, 0%, 100%, 0.6); */
    border-radius: 27px;
    padding-right: 5rpx;
}
.opt {
    width: 50rpx;
    height: 50rpx;
    display: flex;
    justify-content: center;
    align-items: center;
}
.opt-back .back-image {
    width: 10px;
    height: 18px;
}
.line {
    display: block;
    height: 30rpx;
    width: 1px;
    background-color: gray;
}
.opt-home {
    width: 36rpx;
    height: 34rpx;
}
.bar-title {
    width: 45%;
    font-size: 18px;
    // overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    color: #fff;
}
.index {
    padding-bottom: 65px;
}
.index-content {
}
.index-content .bg {
    position: relative;
    height: 214.5px;
    padding: 30px 20px 0 20px;
}
.index-content .bg img {
    position: absolute;
    height: 214.5px;
    width: 100%;
    top: 0;
    left: 0;
}
.index-content .bg > div {
    position: relative;
}

/* 搜索 */
.index .search {
    width: 335px;
    height: 36px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 40;
    img {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0;
        bottom: 0;
        left: 16.5px;
        margin: auto;
    }
    input {
        width: 335px;
        height: 34px;
        background: #f5f5f5;
        color: #bdbdbd;
        border-radius: 17px;
        padding-left: 44.5px;
        box-sizing: border-box;
        font-size: 15px;
        color: #333;
    }
    .close {
        display: inline-block;
        width: 30px;
        height: 18px;
        position: absolute;
        top: 8px;
        // bottom: 0;
        right: 0px;
        // margin: auto;
    }
}
/* .index .search{
    margin: 0 auto;
    margin-top: 14%;
    width: 335px;
    height: 34px;
} */


/* 列表 */
/* .list {
    margin-top: 124px;
} */
.list-header {
    position: fixed;
    top: 128px;
    left: 0;
    width: 100%;
    /* margin-top: 124px; */
    height: 105px;
    padding: 25px 20px 0 20px;
    // box-sizing: border-box;
    display: flex;
    z-index: 20;
    border-bottom: 1px solid #edf1f4;
}
.list-header .tab {
    width: 60px;
    text-align: center;
    margin-left: 30px;
    position: relative;
}
.list-header .tab .red-spot {
    position: absolute;
    top: -8px;
    right: 0px;
    width: 16px;
    height: 16px;
    background: #fe4c48;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
    line-height: 16px;
}
.list-header .tab:nth-of-type(1) {
    margin-left: 0px;
}
.list-header .tab img {
    width: 31px;
    height: 31px;
    display: inline-block;
}
.list-header .tab span {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4d4d4f;
    line-height: 16px;
    margin-left: -2.5px;
    /* text-align: center; */
}
.list-header .tab span.active {
    color: #4b5eb4;
}

.list-content {
    padding: 12px;
    margin-top: 240px;
    /* display: none; */
    /* position: fixed;
    top: 277px;
    left: 0;
    width: 100%; */
}

.list-content .bottom-line {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
}
.customer-service {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: fixed;
    z-index: 20;
    bottom: 75px;
    right: 14px;
}
.customer-service img {
    width: 44px;
    height: 44px;
}
</style>