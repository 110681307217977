<template>
    <div>
        <div class="item bg-fff" @click="goToDetail()">
            <div class="title">
                <!-- <img src="/static/images/index-item-01.png" alt="" /> -->
                <div :class="contractObj.status==3 || contractObj.status==4?'info hs':'info'" style="margin-top: 0;">
                    {{contractObj.name}}
                </div>
            </div>
            <div :class="contractObj.status==3 || contractObj.status==4?'info hs':'info'">
                发起方：{{contractObj.initiator}}
            </div>
            <div :class="contractObj.status==3 || contractObj.status==4?'info hs':'info'">
                合同编号：{{contractObj.code}}
            </div>
            <div :class="contractObj.status==3 || contractObj.status==4?'info hs':'info'">
                合同金额：<span :class="contractObj.status==3 || contractObj.status==4?'hs':''">{{ toDecimal2Fn(contractObj.amount) }}</span>
            </div>
            <div :class="contractObj.status==3 || contractObj.status==4?'info hs':'info'">
                截止签署：{{ dateFormatFn(contractObj.expiresIn) }}
            </div>
            <div :class="contractObj.status==3 || contractObj.status==4?'info hs':'info'" v-if="contractObj.status==4">
                撤销时间：{{ dateFormatFn(contractObj.cancelTime) }}
            </div>
            <div class="status-box">
                <div class="wait-sign" v-if="contractObj.status==1">
                    <img src="~@/assets/yq/wait-sign-status.png">
                    <div>待签署</div>
                </div>
                <div class="no-wait" v-if="contractObj.status!=1">
                    <img :src="require('@/assets/yq/contract-status-'+contractObj.status+'.png')">
                    <!-- <img src="~@/assets/yq/contract-status-1.png" > -->
                </div>
            </div>
            <div class="btn" v-if="contractObj.status==1&&contractObj.signUrl" @click.stop="getContractUrl()">
                签署
            </div>
        </div>
    </div>

</template>

<script>
import { regFenToYuan } from '@/utils/format/unit';
import { parseTime } from "@/utils/index";

export default {
    props: {
        contractObj: {
            default: {},
        },
        authRes: {
            default: {},
        },
        agreements: {
            type: Object,
            default: () => { }
        }
    },
    created() {
        

    },
    methods: {
        getContractUrl() {
            window.location.href = this.contractObj.signUrl;
        },
        goToDetail() {
            let { id, status } = this.contractObj;
            console.log(id, status);
            this.$router.push({
                    path: "/detail",
                    query: {
                        id
                    }
                })
                return;
            // if (status == 2) {
            //     this.$router.push({
            //         path: "/detail",
            //         query: {
            //             id
            //         }
            //     })
            //     return;
            // } else if (status == 1) {
            //     this.getContractUrl();
            // }
        },
        toDecimal2Fn(num){
            return regFenToYuan(num)
        },
        dateFormatFn(val){
            return parseTime( val );
        }
    },

};
</script>

<style scoped>
.list-content .item {
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0px 4px 10px 0px rgba(223, 224, 226, 0.31);
}

.list-content .item .title {
    display: flex;
    height: 30px;
    align-items: center;
    margin-bottom: 7px;
}
.list-content .item .title .hs {
    color: #a8a8a8;
}
.list-content .item .title img {
    width: 34px;
    height: 30px;
}
.list-content .item .title > div {
    font-size: 18px;
    color: #222222;
    font-weight: 500;
}

.list-content .item .info {
    margin-top: 4px;
    color: #666666;
    font-size: 13px;
}
.list-content .item .info.hs {
    color: #a8a8a8;
}
.list-content .item .info span {
    color: #ff3000;
}
.list-content .item .info .hs {
    color: #a8a8a8;
}
.list-content .item .status-box {
    position: absolute;
    top: 0;
    right: 0;
}
.list-content .status-box .wait-sign {
    width: 59px;
    height: 21.5px;
    position: relative;
}
.list-content .item .wait-sign img {
    width: 59px;
    height: 21.5px;
}
.list-content .item .wait-sign div {
    font-size: 11px;
    font-weight: 500;
    color: #b87942;
    position: absolute;
    top: 2.5px;
    right: 10px;
    z-index: 10;
}
.list-content .status-box .no-wait img {
    width: 99px;
    height: 67px;
}
.list-content .item .btn {
    position: absolute;
    bottom: 31.5px;
    right: 20.5px;
    /* position: relative; */
    width: 76px;
    height: 30px;
    background: linear-gradient(-90deg, #4b62bd, #5267bf);
    border-radius: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    color: #ffffff;
}
</style>
