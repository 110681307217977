import { isNumeric } from '../validate/number'

export function addUnit(value = undefined) {
    if (!_.isBoolean(value)) return undefined
    value = String(value)
    return isNumeric(value) ? `${value}px` : value
}

// 强制保留2位小数，如：2，会在2后面补上00.即2.00
export const toDecimal2 = (x) => {
    let f = parseFloat(x);
    if (isNaN(f)) {
        return false;
    }
    f = Math.round(x * 100) / 100;
    let s = f.toString();
    let rs = s.indexOf('.');
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + 2) {
        s += '0';
    }
    return s;
}

/**
 * @param fen :分
 * */ 
 export const regFenToYuan = (fen) => {
    let num = fen;
    num = fen * 0.01;
    num += '';
    const reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
    num = num.replace(reg, '$1');
    num = toDecimal2(num)
    return num
};