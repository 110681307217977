<template>
    <!-- 列表为空显示 -->
    <div class="index-empty">
        <div class="empty-img">
            <img src="@/assets/yq/index-empty.png" alt="" />
        </div>
        <div class="text-sm font-weight-xs">暂无合同数据哦!</div>
        <div class="contact">
            <div>
                感谢您选择卓猎云签，如有疑问请联系业务员或拨打客服电话
                <a :href="'tel:' + telePhone">{{telePhone}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config/config'
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        tip: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            telePhone: ""
        }
    },
    created(){
        this.telePhone = config.telePhone
    }
};
</script>

<style scoped>
.index-empty {
    padding-top: 70px;
    border-radius: 25px 25px 0 0;
}
.index-empty > div {
    text-align: center;
}
.index-empty .empty-img img {
    width: 145.5px;
    height: 79px;
}
.index-empty .contact {
    margin-top: 25px;
    text-align: center;
}
.index-empty .contact > div {
    width: 251px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    color: #999999;
}
.index-empty .contact a {
    font-size: 14px;
    line-height: 21px;
    color: #2d74fe;
}
</style>
